import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    margin-top: 62px;
    .Acardion{
        background-color: #212121;
        color: white;
        margin-bottom: 10px;
        h3{
            margin: 0;
            padding: 0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 50px;
            line-height: 69px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        p{
            margin: 0;
            padding: 0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            width: 47%;
        }
        .css-i4bv87-MuiSvgIcon-root{
            background: #FFD700;
            border-radius: 50%;
            padding: 2px;
        }
    }
    @media only screen and (max-width:768px) {
        p{
            width: 95% !important;
        }
    }
    @media only screen and (max-width:504px) {
        h3{
            margin: 0;
            padding: 0;
            font-size: 30px !important;
            line-height: 69px;
        }
    }
`