import Header from "../Dashboard_Header/Dashboard_Header"
import { Wrapper } from "./styled-index"

function DashboardMain({children}) {
    return(
        <Wrapper>
            <div class="container">
        <Header/>
        <div class="main">
            <div class="cardBox">
                {children}
            </div>
        </div>
    </div>
        </Wrapper>
    )
}
export default DashboardMain