import { Wrapper  , Reklama} from "./styled-index"
import Strelka from "./../../../assets/home/strelka.svg"
import NavLinkCommon from "./../../../component/common/link/index"
import { useState } from "react"
import { useTranslation } from "react-i18next";
function AcardionReklama() {
    const [ReklamaState , setReklamaState ] = useState(true);

    const ClickReklama = () =>{
        setReklamaState(false)
    }
    const ClickReklamaOpen = () =>{
        setReklamaState(true)
    }
    const [t , i18n] = useTranslation();
    return(
        <Wrapper>

            
            {ReklamaState === true  ?(<button onClick={ClickReklama}><img src={Strelka} width={12} height={6} alt="Icon strelka" /></button>) : ReklamaState === false  ?(               <button onClick={ClickReklamaOpen}><img src={Strelka} className="Strelka-rotet1" width={12} height={6} alt="Icon strelka" /></button>   ):null}
{     ReklamaState === true  ?(     
             <Reklama>
                <h2>{t("Acardion.0")}</h2>
                <p>{t("Acardion.1")}</p>
                <NavLinkCommon Link={false} href="#form" padding={"23px 43px"} title={t("Acardion.2")} />
            </Reklama>) : ReklamaState === false  ?(      
                <Reklama style={{height : "0px"}}>
            </Reklama>):null}
        </Wrapper>
    )
}
export default AcardionReklama