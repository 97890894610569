import styled from "styled-components";
import BackgroundImage from "./../../../assets/home/Home-about-background.png"
import BackgroundImageMedia from "./../../../assets/home/Home-about-background-media.png"

export const Wrapper = styled.div`
    background-image: linear-gradient(rgba(27, 27, 27, 0.8),rgba(27, 27, 27, 0.8)), url(${BackgroundImage}) ;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 518px;
    @media only screen and (max-width:673px) {
        background-image: linear-gradient(rgba(27, 27, 27, 0.8),rgba(27, 27, 27, 0.8)), url(${BackgroundImageMedia}) !important ;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 639px;
    }
`
export const Container = styled.div`
        max-width: 1380px;
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        h3{
        margin: 0;
        padding: 0;
        padding-top: 101px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 69px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 37px;
    }
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    ul li{

        list-style-type: none;
        border: 1px solid  rgba(229, 229, 229, 0.05);
        padding:  84px 0px;
        width: 350px;
        text-align: center;
        transition: 1s;
        cursor: pointer;
    }
    @media only screen and (max-width :1200px) {
        padding: 0;
        ul li{
        list-style-type: none;
        border: 1px solid  rgba(229, 229, 229, 0.05);
        padding:  77px 0px;
        width: 281px;
        text-align: center;
        transition: 1s;
        cursor: pointer;
    }
}
    @media only screen and (max-width :678px) {
        padding: 0;
        ul{
            flex-wrap: wrap;
        }
        ul li{
        list-style-type: none;
        border: 1px solid  rgba(229, 229, 229, 0.05);
        padding:  77px 0px;
        width: 281px;
        text-align: center;
        transition: 1s;
        cursor: pointer;
    }
}
@media only screen and (max-width:673px) {
    ul li{
        list-style-type: none;
        border: 1px solid  rgba(229, 229, 229, 0.05);
        padding:  36px 0px;
        width: 168px;
        text-align: center;
        transition: 1s;
        cursor: pointer;
    }
    }
    ul li:hover{
        background: linear-gradient(263.32deg, rgba(84, 84, 84, 0.62) 1.12%, rgba(60, 60, 60, 0.62) 99.93%);
        transition: 1s;
    }
    ul li h4{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 80px;
        line-height: 69px;
        text-transform: uppercase;
        color: #FFD700;
    }
    ul li span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }
`