import AboutComponent from "../../component/about"
import AboutCommand from "../../component/about/about-command"
import ServicesComponent from "../../component/home/home-services"
import Maps from "../../component/map"
import Header from "../../component/header"
import Footer from "../../component/footer"
import Network from "../../component/networks"
import FixedAnime from "../../component/fixedAnime"
function About() {
    return(
        <>
        <Header/>
        <Network/>
        <AboutComponent/>
        <AboutCommand/>
        <ServicesComponent/>
        <Maps/>
        <Footer/>
        <FixedAnime/>
        </>
    )
}
export default About