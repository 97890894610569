import { useTranslation } from "react-i18next"
import { Section , Wrapper } from "./styled-index"
import TeamComponent from "../../component/team";
import AcardionReklama from "../../component/home/acardion-reklama";
import Header from "../../component/header"
import Footer from "../../component/footer"
import Network from "../../component/networks"
import FixedAnime from "../../component/fixedAnime";
function Team() {
    const {t , i18n} = useTranslation();
    return(
        <>
        <Header/>
        <Network/>
                <Section>
            <Wrapper>
                <h1><span>{t("AboutPage.2")}</span></h1>
                <p>{t("AboutPage.3")}</p>
            </Wrapper>
        </Section>
        <AcardionReklama/>
        <TeamComponent/>
        <Footer/>
        <FixedAnime/>

        </>
    )
}
export default Team