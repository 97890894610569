import styled from "styled-components";
import FooterBackground from "./../../assets/component/Footer-background.png"
import FooterBackgroundMobile from "./../../assets/component/Footer-background-mobile.png"

export const Section = styled.div`
    width: 100%;
    border-top: 10px solid #FFD700;
    margin-top: 199px;
    background-image: linear-gradient(rgba(27, 27, 27, 0.9),rgba(27, 27, 27, 0.9)), url(${FooterBackground});
    background-repeat: no-repeat;
    background-size: contain;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    background-image: linear-gradient(rgba(27, 27, 27, 0.9),rgba(27, 27, 27, 0.9)), url(${FooterBackgroundMobile});
    background-size: cover;
    .Desktop-qr{
        display: none;
    }
    .Mobile-qr{
        display: flex !important;
    }
}
.Desktop-qr{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.71);
    position: relative;
    top: 50px;
}
.Mobile-qr{
    display: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.71);
}
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding-top: 55px;
    padding-bottom: 82px;
    @media only screen and (max-width: 1200px) {
    width: 95%;
    }
    @media only screen and (max-width: 1032px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
}
@media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items:flex-start;
        text-align: left;
}
`
export const Left = styled.div`

    h3{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 58px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    ul li .NavLink {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;
        color: #FFFFFF;
        text-decoration: none;
    }
`
export const Center = styled.div`
    @media only screen and (max-width: 1032px) {
        flex-direction: column;
        text-align: center;
}
@media only screen and (max-width: 768px) {
        flex-direction: column;
        text-align: left;
}
`
export const Center2 = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
a{
    background-color: transparent;
    border: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #FFD700;
}
`
export const Right = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a:nth-child(1){
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 32px;
        color: #FFFFFF;
        text-decoration: none;
    }
    a:nth-child(2){
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 32px;
        color: #FFFFFF;
        text-decoration: none;
    }
    a:nth-child(3){
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 32px;
        color: #FFFFFF;
        text-decoration: none;
    }
    a:nth-child(4){
        background: #FFD700;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: #1B1B1B;
        padding: 23px 42px;
        text-decoration: none;
    }
    @media only screen and (max-width: 425px) {
    width: 100%;
        a:nth-child(4){
        background: #FFD700;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: #1B1B1B;
        padding: 23px 0;
        text-decoration: none;
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 50px;
    }
    a:nth-child(1){
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 32px;
        color: #FFFFFF;
        text-decoration: none;
        margin-top: 35px;
        margin-bottom: 20px;

    }
    a:nth-child(2){
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 32px;
        color: #FFFFFF;
        text-decoration: none;
        margin-bottom: 20px;
    }
    a:nth-child(3){
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 32px;
        color: #FFFFFF;
        text-decoration: none;
    }
}
`