import { useContext } from "react"
import {Wrapper} from "./styled-index"
import { SitesContext } from "../../../context/sites/context"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useState } from "react"
function Sites({sitesComplex}) {
    const { SitesMap} = useContext(SitesContext)
    const {t , i18n} = useTranslation();
    return(
        <Wrapper>
        <h3>{t("About.6")}</h3>
            <ul>
            {SitesMap.slice(0,6).map((elem) =>(
                <li>
                    <a href={elem.link} target="_blank">
                    <img className="image" src={elem.background} width={698} height={364} alt="" />
                    <span>{elem.title}</span>
                    </a>
                </li>
            ))}
            </ul>
                
                {sitesComplex === true ? <NavLink className={'NavLink'} to="/projects">{t("Sites.0")}</NavLink> : null}
        </Wrapper>
    )
}
export default Sites