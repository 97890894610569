import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom";
import { Content, ContentTop } from "../map/styled-index";
import { Wrapper , Left , Center , Center2 , Right , Section } from "./styled-index"
import CompanyCatalogRu  from "./../../assets/company_ru.pdf"
import CompanyCatalogUz  from "./../../assets/company_uz.pdf"
import CompanyCatalogen from "./../../assets/company_en.pdf"

function Footer() {
    const {t , i18n} = useTranslation();
    function GetLangValue() {
      return  window.localStorage.getItem("i18nextLng")   
    }
    return(
        <Section>
                    <Wrapper>
            <Left>
                <h3>{t("Footer.0")}</h3>
                    <ul>
                        <li><NavLink className={"NavLink"} to="/about">{t("Header.2")}</NavLink> </li>
                        <li><NavLink className={"NavLink"} to="/services">{t("Header.0")}</NavLink> </li>
                        <li><NavLink className={"NavLink"} to="/projects">{t("Header.1")}</NavLink> </li>
                        <li><NavLink className={"NavLink"} to="/blog">{t("Header.4")}</NavLink> </li>
                    </ul>
                    <span className="Desktop-qr">© Supersite 2022</span>
            </Left>
            <Center>
            <Content>
            <ContentTop>
                <h3>{t("Maps.1")}</h3>
                <p>{t("Maps.2")}</p>

            </ContentTop>
            <ContentTop>
            <h3>{t("Maps.3")}</h3>
                <p>{t("Maps.4")}</p>
            </ContentTop>
        </Content>
            </Center>
            <Center2>
            <ContentTop>
            <h3>{t("Maps.5")}</h3>
                <p>{t("Maps.6")}</p>
            </ContentTop>
            {GetLangValue() == "ru" ? <a href={CompanyCatalogRu} download="Каталог компании">{t("Footer.1")}</a> : GetLangValue() == "uz" ? <a href={CompanyCatalogUz} download="Kompaniya katalogi">{t("Footer.1")}</a> : GetLangValue() == "en" ? <a href={CompanyCatalogen} download="Company Catalog">{t("Footer.1")}</a> : null }
            
            </Center2>
            <Right>
            <a href="tel:+998998107090">+99899 810-70-90</a>
            <a href="tel:+998998147090">+99899 814-70-90</a>
            <a href="tel:+998958157090">+99895 815-70-90</a>
            <a href="#form">{t("Home-hero.4")}</a>
            <span className="Mobile-qr">© Supersite 2022</span>
            </Right>
        </Wrapper>
        </Section>
    )
}
export default Footer