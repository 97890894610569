import {Wrapper} from "./index-styled"
import ServicesComponent from "../../component/services"
import Header from "../../component/header"
import Footer from "../../component/footer"
import Network from "../../component/networks"
import FixedAnime from "../../component/fixedAnime"
function Services() {
    return(
        <>
        <Header/>
        <Network/>
        <Wrapper>
            <ServicesComponent/>
        </Wrapper>
        <Footer/>
        <FixedAnime/>

        </>
    )
}
export default Services