import styled from "styled-components";
import reklamaBackground from "./../../../assets/home/reklama-background.png"
export const Wrapper = styled.div`
    text-align: center;
    margin-top: -50px;
    button{
    background: #FFD700;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    border: transparent;
    position: relative;
    top: -60px;
    cursor: pointer;
}   
@media only screen and (max-width:1200px) {
    button{
        top: 0px;

}
}
@media only screen and (max-width:768px) {
    button{
        top: 25px;

}
}
.Strelka-rotet1{
    transform: rotate(180deg);
    transition: 2s;
}
@media only screen and (max-width:600px) {
    button{
        top: 25px;
    right: auto;
    left: auto;
    margin-left: -13px;
}
}
`
export const Reklama = styled.div`
    background-image: url(${reklamaBackground});
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0px;
    transition: 2s;
    height: 306px;


    h2{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 69px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-top: 45px;
        
    }
    p{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        width: 550px;
        margin: 0 auto;
        margin-bottom: 80px;
}
@media only screen and (max-width:768px) {
        h2{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
}
}
@media only screen and (max-width:600px) {
        h2{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    p{
        width: 100%;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
}
}
`