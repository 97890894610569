import { useState , useEffect } from "react";
import { createContext } from "react";

export const VaucherContext = createContext({
    VaucherMap:[],
})

export const VaucherProviderAdmin = ({children})=>{
    const [VaucherMap , setVaucherMap] = useState([])

    useEffect(() => {
        fetch("https://api.supersite.uz/claimVoucher",{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
      
          })
        .then(res => res.json())
        .then(result => setVaucherMap(result))
    }, [])

    const value = { VaucherMap};
    return(
        <VaucherContext.Provider value={value}>{children}</VaucherContext.Provider>
    )
}