import styled from "styled-components";

export const Wrapper = styled.div`
    form {
        display: flex;
        flex-direction: column;
        width: 35%;
    }
    @media only screen and (max-width:768px) {
        form {
        width: 100%;
    }
    }
    form input{
        height: 23px;
        background-color: transparent;
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        margin-bottom: 35px;
        outline: transparent;
        color: white;
    }
    form button {
        width: 301px !important;
    }
    input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
@media only screen and (max-width : 768px) {
    form input {
        width: 100%;
    }
    form button {
        width: 100% !important;
    }
}
`