import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { Wrapper , WrapperContainer } from "./styled-index"

function AboutCommand() {
    const {t , i18n} = useTranslation();
    return(
        <Wrapper>
            <WrapperContainer>
                <h3>{t("AboutPage.2")}</h3>
                <p>{t("AboutPage.3")}</p>
        <NavLink className={"NavLink"} to="/team">{t("AboutPage.4")}</NavLink>
            </WrapperContainer>
        </Wrapper>
    )
}
export default AboutCommand