import { Wrapper , Left } from "./styled-index"
import NotFoundImage from "./../../assets/notFound/404Image.png"
import { useTranslation } from "react-i18next"
import Header from "../../component/header"
import Footer from "../../component/footer"
import Network from "../../component/networks"
import FixedAnime from "../../component/fixedAnime"
function NotFound() {
    const {t , i18n} = useTranslation();

    return(
            <>
            <Header/>
            <Network/>
            <Wrapper>
            <img src={NotFoundImage} width={300} height={250} alt="not found" />
            <Left>
                <h3>{t("NotFound.0")}</h3>
                <span>{t("NotFound.1")}</span>
            </Left>
        </Wrapper>
        <Footer/>
        <FixedAnime/>

        </>
    )
}
export default NotFound