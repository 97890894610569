import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 169px;
    ul{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 57px;
    }
    ul li {
        width: 50%;
        list-style-type: none;
        position: relative;
    }
    ul li a {
        text-decoration: none;
        margin-bottom: 100px;
    }
    ul li span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        color: #1B1B1B;
        background: #FFD700;
        padding: 10px;
        position: absolute;
        bottom: 15px;
        left: 10px;
    }
    h3{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 69px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 60px;
    }
    .NavLink{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: #FFD700;
        border: 1px solid #FFD700;
        padding: 23px 22px;
        width: 274px;
        text-decoration: none;
        display: flex;
        margin-left: auto;
    }
    @media only screen and (max-width: 1400px) {
        width: 95%;
        margin: 0 auto;
        .image{
            width: 558px;
            height: 334px;
        }
    }
    @media only screen and (max-width: 1126px) {
        ul{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
        width: 95%;
        margin: 0 auto;
        .image{
            width: 100%;
            height: 100%;
        }
    }
    @media only screen and (max-width: 562px) {
        ul{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        }
        ul li {
        width: 100%;
        list-style-type: none;
        position: relative;
        margin-bottom: 23px;
    }
        width: 95%;
        margin: 0 auto;
        .image{
            width: 100%;
            height: 100%;
        }
    }
`
