import { useState , useEffect } from "react";
import { createContext } from "react";

export const ConnectContext = createContext({
    ConnectMap:[],
})

export const ConnectProviderAdmin = ({children})=>{
    const [ConnectMap , setConnectMap] = useState([])

    useEffect(() => {
        fetch("https://api.supersite.uz/contactUs",{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
      
          })
        .then(res => res.json())
        .then(result => setConnectMap(result))
    }, [])

    const value = { ConnectMap};
    return(
        <ConnectContext.Provider value={value}>{children}</ConnectContext.Provider>
    )
}