import { useContext } from "react"
import { SitesContext } from "../../../../context/admin-panel/sites/context"
import { TeamContext } from "../../../../context/admin-panel/team/context"
import {Wrapper} from "./styled-index"

function Detials() {
    const { TeamMap } = useContext(TeamContext)
    const {SitesMap} = useContext(SitesContext)
    return(
        <Wrapper>
                        <div class="details">
                <div class="recentOrders">
                    <table>
                        <thead>
                            <tr>
                                <td>картинка рабочих Имя</td>
                                <td>должность</td>
                            </tr>
                        </thead>

                        <tbody>
                            {TeamMap.map(elem => 
                             <tr>
                                    <td>
                                    <img alt="" src={elem.imageavatar} width={50} style={{borderRadius:"50%"}} height={50} />
                                    <span>{elem.workername}</span>
                                    </td>
                                    <td>{elem.workerposition}</td>
                                </tr>
                                ) }
                        </tbody>
                    </table>
                </div>

                <div class="recentCustomers">
                    <div class="cardHeader">
                        <h2>Наши работы</h2>
                    </div>

                    <ul>
            {SitesMap.map((elem) =>(
                <li>
                    <a href={elem.link} target="_blank">
                    <img className="image" src={elem.background} width={258} height={144} alt="" />
                    <span>{elem.title}</span>
                    </a>
                </li>
            ))}
            </ul>
                </div>
            </div>
        </Wrapper>
    )
}
export default Detials