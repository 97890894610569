import { Overlay , ModalWrapper, Wrapper } from "./styled-index"
import { useTranslation } from "react-i18next"

function Modal({setModals}) {
    const {t , i18n} = useTranslation(); 
    return(
        <>
        <Wrapper>
        <ModalWrapper>
        <a href="tel:+998998107090">+99899 810-70-90</a>
        <a href="tel:+998998147090">+99899 814-70-90</a>
        </ModalWrapper>
        </Wrapper>
        <Overlay onClick={setModals}>
        </Overlay>
        </>
    )
}
export default Modal