import { useContext } from "react"
import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper } from "../../../component/admin-panel/dashboard-main/styled-index"
import {Background, Container } from "./styled-index";
import { ReviewsContext } from "../../../context/admin-panel/video/context";
import CardReviews from "../../../component/admin-panel/cardReviews";
import ReviewsDeletPutComponent from "../../../component/admin-panel/cardReviews/client-delet-put";
import ReviewsModal from "../../../component/admin-panel/cardReviews/modal-form";
function AdminReviews() {
    const { ReviewsMap} = useContext(ReviewsContext);
    return(
        <>
            <Wrapper>
            <DashboardMain>
                <ReviewsModal/>
                <Container>
                {ReviewsMap.map(elem =>  
                         <Background>
                            <CardReviews elem={elem} key={elem.id}/>
                    <ReviewsDeletPutComponent DeleteId={elem.id} Element={elem} PutBlog={elem.id}/>
                    </Background>
    )}
                </Container>
            </DashboardMain>
        </Wrapper>
        </>
    )
}
export default AdminReviews