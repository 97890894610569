import {Wrapper} from "./index-styled"
import HomeHero from "../../component/home/home-hero"
import AcardionReklama from "../../component/home/acardion-reklama"
import CompanyName from "../../component/home/company-name"
import HomeAbout from "../../component/home/home-about"
import Sites from "../../component/home/sites"
import Clients from "../../component/home/clients"
import ContactForm from "../../component/home/contact-form"
import Maps from "../../component/map"
import ServicesComponent from "../../component/home/home-services"
import Header from "../../component/header"
import Footer from "../../component/footer"
import Network from "../../component/networks"
import FixedAnime from "../../component/fixedAnime"

function Home() {
    return(
        <div>
               <Header/>
               <Network/>
        <Wrapper>
            <HomeHero/>
            <AcardionReklama/> 
            <CompanyName/>
            <HomeAbout/>
            <ServicesComponent/>
            <Sites sitesComplex={true}/>
            <Clients/>
            <ContactForm/>
            <Maps/>
        </Wrapper> 
        <Footer/>
        <FixedAnime/>

        </div>
    )
}
export default Home