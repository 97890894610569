import styled from "styled-components";
import  PhoneInput  from 'react-phone-input-2';

export const Wrapper = styled.div`
    form {
        display: flex;
        flex-direction: column;
        width: 35%;
    }
    @media only screen and (max-width: 768px) {
        form{
            width: 100%;
        }
    }
    form input{
        height: 23px;
        background-color: transparent;
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        margin-bottom: 35px;
        outline: transparent;
        color: white;
    }
    form button {
        width: 301px !important;
    }
    input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
@media only screen and (max-width : 768px) {
    form input {
        width: 100%;
    }
    form button {
        width: 100% !important;
    }
}
`
export const ReactPhoneInput = styled(PhoneInput)`
  margin-top: 10px;
  height: 60px;
  width: 100% !important;
  margin-bottom: 28px !important;
    @media only screen and (max-width: 769px) {
    width: 100% !important;

    }
  input {
    width: 100% !important;
    height: 50px !important;
    background: transparent !important;
  }

  .special-label{
    display: none;
  }
 .flag-dropdown{
    width: 50px !important;
    height: 50px !important;
    background-color: #FFD700 !important;
  }
.form-control{
    padding-left: 60px !important;
  }
  .selected-flag{
    background-color: #FFD700 !important;
  }
  .country-list{
    background-color: #FFD700 !important;
  }
  .country-list .country{
    background-color: #FFD700 !important;
  }
`