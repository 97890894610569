import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {useParams} from 'react-router-dom'
import BlogIdCompnonent from '../../component/blogId';
import Header from '../../component/header';
import AcardionReklama from '../../component/home/acardion-reklama';
import Maps from '../../component/map';
import Network from '../../component/networks';
import { BlogContext } from '../../context/blog/context';
import { Section, Wrapper, Wrappers } from '../blog/index-styled';
import FixedAnime from '../../component/fixedAnime';

function BlogId() {
    const {titleUz , id} = useParams();
    const {t  , i18n} = useTranslation();
    return(
        <>
        <Header/>
        <Network/>
                <Wrappers>
        <Section>
            <Wrapper>
                <h1><span>{t("Blog.0")}</span></h1>
            </Wrapper>
        </Section>
            <AcardionReklama/>
            <BlogIdCompnonent id={id}/>
            <Maps/>
            </Wrappers>
        <FixedAnime/>

        </>
    )
}
export default BlogId