import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Data } from "./data/data";
import { Wrapper , WrapperItem , WrappperItemRight , WrapperItemLeft , ItemList } from "./styled-index";
import { useTranslation } from "react-i18next";


function ServicesComponent() {
    const {t , i18n} = useTranslation();
    function GetValue() {
      return window.localStorage.getItem("i18nextLng")
    }
    console.log(GetValue());
    return(
        <Wrapper>
         <h3>{t("Header.0")}</h3>
            <WrapperItem>
            <WrappperItemRight>
            <Accordion className="Acardion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("Services.0")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {Data.slice(0 , 4).map((elem) =>
                <ItemList>
                  {GetValue() === 'ru' ? <span>{elem.TitleCategoryRu}</span> : GetValue() === 'uz' ? <span>{elem.TitleCategoryUz}</span> : GetValue() === 'en' ? <span>{elem.TitleCategoryEn}</span>:null}
                    
                </ItemList>
            )}
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Acardion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("Services.1")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

          {Data.slice(4 , 7).map((elem) =>
                <ItemList>
                  {GetValue() === 'ru' ? <span>{elem.TitleCategoryRu}</span> : GetValue() === 'uz' ? <span>{elem.TitleCategoryUz}</span> : GetValue() === 'en' ? <span>{elem.TitleCategoryEn}</span>:null}
                </ItemList>
            )}
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Acardion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("Services.2")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {Data.slice(7 , 11).map((elem) =>
                <ItemList>
                  {GetValue() === 'ru' ? <span>{elem.TitleCategoryRu}</span> : GetValue() === 'uz' ? <span>{elem.TitleCategoryUz}</span> : GetValue() === 'en' ? <span>{elem.TitleCategoryEn}</span>:null}
                </ItemList>
            )}
            
          </Typography>
        </AccordionDetails>
      </Accordion>
            </WrappperItemRight>
            <WrapperItemLeft>
            <Accordion className="Acardion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("Services.3")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {Data.slice(11, 14).map((elem) =>
                <ItemList>
                  {GetValue() === 'ru' ? <span>{elem.TitleCategoryRu}</span> : GetValue() === 'uz' ? <span>{elem.TitleCategoryUz}</span> : GetValue() === 'en' ? <span>{elem.TitleCategoryEn}</span>:null}
                </ItemList>
            )}
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Acardion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("Services.4")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {Data.slice(14, 18).map((elem) =>
                <ItemList>
                  {GetValue() === 'ru' ? <span>{elem.TitleCategoryRu}</span> : GetValue() === 'uz' ? <span>{elem.TitleCategoryUz}</span> : GetValue() === 'en' ? <span>{elem.TitleCategoryEn}</span>:null}
                </ItemList>
            )}
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Acardion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("Services.5")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {Data.slice(18, 24).map((elem) =>
                <ItemList>
                  {GetValue() === 'ru' ? <span>{elem.TitleCategoryRu}</span> : GetValue() === 'uz' ? <span>{elem.TitleCategoryUz}</span> : GetValue() === 'en' ? <span>{elem.TitleCategoryEn}</span>:null}
                </ItemList>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
            </WrapperItemLeft>
            </WrapperItem>
    
        </Wrapper>
    )
}
export default ServicesComponent