import styled from "styled-components";

export const Wrapper = styled.div`
button{
    background-color: #FFD700;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border: none;
    margin-bottom: 10px;
    margin-left: 20px;
}
`
