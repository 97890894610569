import styled from "styled-components";
import BackgroundImage from "./../../../assets/about/Backgorund.png"
import BackgroundMobile from "./../../../assets/about/Background-mobile.png"

export const Wrapper = styled.div`
    background-image: linear-gradient(rgba(27, 27, 27, 0.8),rgba(27, 27, 27, 0.8)), url(${BackgroundImage});
    background-size: contain;
    background-repeat: no-repeat;
    height: 525px;
    margin-top: 111px;
    @media only screen and (max-width:1200px) {
        background-size: cover;

    }
    @media only screen and (max-width:540px) {
        background-size: cover;
        background-image: linear-gradient(rgba(27, 27, 27, 0.8),rgba(27, 27, 27, 0.8)), url(${BackgroundMobile});
    }
`
export const WrapperContainer = styled.div`
max-width: 1400px;
width: 100%;
margin: 0 auto;
@media only screen and (max-width:1200px) {
        width: 95%;
        h3{
        padding-top: 37px !important;
        }
    }
    
    @media only screen and (max-width:768px) {
        p{
            width: 100% !important;
        }
    }
h3{
    margin: 0;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 69px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 137px;
    margin-bottom: 10px;
}
p{
    margin: 0;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    width: 597px;
    margin-bottom: 52px;
}
.NavLink{
    border: 1px solid #FFD700;
    background-color: transparent;
    padding: 23px 43px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #FFD700;
    text-decoration: none;
}
`