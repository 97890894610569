import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper } from "./styled-index"
import CardBox from "../../../component/admin-panel/dashboard/cardBox"
import Detials from "../../../component/admin-panel/dashboard/detials"
function AdminDashboard() {
    return(
        <Wrapper>
            <DashboardMain>
                <CardBox/>
                <Detials/>
            </DashboardMain>
        </Wrapper>
    )
}
export default AdminDashboard