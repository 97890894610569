import { useContext, useState } from "react"
import BlogModal from "../../../component/admin-panel/Blog/modal-form"
import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper } from "../../../component/admin-panel/dashboard-main/styled-index"
import { BlogContext } from "../../../context/admin-panel/Blog/context"
import {Container} from "./styled-index"
import BlogDeletPutComponent from "../../../component/admin-panel/Blog/Blog-delet-put"

function AdminBlog() {
    const {BlogMap} = useContext(BlogContext);

    return(
        <>
            <Wrapper>
            <DashboardMain>
                <BlogModal />
                <Container>
                <table id="table">
                    <thead id="thead">
                      <th id="th">Заголовок , Описание</th>
                      <th id="th">Изменить Удалить</th>
                    </thead>
                    <tbody id="tbody">
                        {BlogMap.map((elem) =>
                      <tr>
                        <td>{elem.titleru} <p> {elem.descriptionru}</p></td>
                        <td><BlogDeletPutComponent Element={elem} DeleteId={elem.id} PutBlog={elem.id}/></td>
                     </tr>
                        )}
                    </tbody>
                   </table>
                </Container>
            </DashboardMain>
        </Wrapper>
        </>
    )
}
export default AdminBlog