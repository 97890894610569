import styled from "styled-components";

export const WrapperItem = styled.div`
    display: flex;
    .Acardion{
        width: 100%;
        background-color: #FFD700;
        color: #1B1B1B;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 58px;
        text-transform: uppercase;
        border-radius: 0px !important;
        border: 1px solid black;
        text-align: center;
        border: 2px solid #1B1B1B;
    }
    .css-15v22id-MuiAccordionDetails-root{
        padding: 0;
    }
    @media only screen and (max-width: 582px) {
        flex-direction:column ;
}
`
export const ItemList = styled.div`
        background-color: #1B1B1B;
        border: 1px solid rgba(229, 229, 229, 0.05);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 38px;
        text-transform: uppercase;
        color: #FFFFFF;
`
export const WrappperItemRight = styled.div`
width: 49%;
@media only screen and (max-width: 582px) {
width: 100%;

}
`
export const WrapperItemLeft = styled.div`
width: 49%;
@media only screen and (max-width: 582px) {
width: 100%;

}
`
export const Wrapper = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    @media only screen and (max-width:1200px) {
      width: 95%;
}
    h3{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 69px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 60px;
        margin-top: 167px;
    }
`
