import styled from "styled-components";

export  const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    margin-top: 167px;
    @media only screen and (max-width:1200px) {
        flex-direction: column;
        width: 95%;
    }
    @media only screen and (max-width:768px) {
      margin-top: 57px;
        img{
            width: 100% !important;
            height: auto !important;
        }

    }
`
export const WrapperLeft = styled.div`
    margin-top: 87px;
    width: 648px ;
    @media only screen and (max-width:768px) {
        width: 100%;
        margin-bottom:50px;

    }
    h2{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 80px;
        line-height: 86px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 20px;
        @media only screen and (max-width: 575px) {
        font-size: 40px;
        font-weight: 600;
    }
    }

    p{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        margin-bottom: 32px;
    }
    span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }
`