import { useState } from "react";
import { useTranslation } from "react-i18next"
import { Wrapper , Top , WrapperForms , ButtonActives } from "./styled-index"
import RequestVaucher from "./requst-vaucher";
import ContactUs from "./contact-us";
function ContactForm() {
    const {t , i18n} = useTranslation();
    const [Active , SetActive] = useState(true)
    
    const HandleForm = () =>{
        SetActive(true)
    }
    const HandleForm2 = () =>{
        SetActive(false)
    }
    return(
        <Wrapper id="form">
            <WrapperForms>
            <Top>
                {Active  === true ?  <ButtonActives style={{color : "#FFD700"  , backgroundColor : "rgba(51, 51, 51, 0.15)" , border : "1px solid transparent"}}>{t("Form.0")}</ButtonActives> : Active  === false ? <ButtonActives onClick={HandleForm} style={{color: "#555555", backgroundColor : "transparent" , border : "1px solid rgba(51, 51, 51, 0.15)"}}>{t("Form.0")}</ButtonActives>:null}
                {Active  === true ?  <ButtonActives onClick={HandleForm2}  style={{color : "#555555" , backgroundColor : "transparent" , border : "1px solid rgba(51, 51, 51, 0.15)"}}>{t("Form.1")}</ButtonActives> : Active  === false ? <ButtonActives  style={{color : "#FFD700" , backgroundColor : "rgba(51, 51, 51, 0.15)", border : "1px solid transparent"}}>{t("Form.1")}</ButtonActives>:null}
            </Top>   
                {Active === true ? <RequestVaucher/> : Active === false ? <ContactUs/> :null }
            </WrapperForms>
        </Wrapper>
    )
}
export default ContactForm