import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
justify-content: space-between;
width: 96.5%;
margin-top: 20px;
button{
    background-color: #FFD700;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border: none;
    margin-bottom: 10px;
    margin-right: 10px;
}
`
