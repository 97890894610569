import NavLinkCommon from "../common/link"
import { Networks } from "./index-styled"
import Telephon from './../../assets/home/telephone-icon.svg'
import Telegram from './../../assets/home/telegram-icon.svg'
import Instagram from './../../assets/home/instagram-icon.svg'
import Button from "../common/button"
import Modal from "./telephone-modal"
import { useState } from "react"
function Network() {
    const [Modals  , setModals] = useState(false);

    return(
        <>
        <Networks>
            <Button onClick={() => setModals(true)}  padding={"12px 15px 12px 15px"}  title={<img src={Telephon} width={27} height={27} alt="telephon"/>} />
            <NavLinkCommon target="_blank" href={"https://t.me/supersiteuz"} padding={"24px 15px 12px 15px"} Link={false} title={<img src={Telegram} width={30} height={30} alt="telegram"/>} />
            <NavLinkCommon target="_blank" href={"https://instagram.com/supersite.uz?igshid=YmMyMTA2M2Y="} padding={"24px 11px 12px 15px"} Link={false} title={<img src={Instagram} width={34} height={34} alt="instagram"/>} />
        </Networks>
        {Modals === true ? <Modal setModals={() => setModals(false)}/> : null}
        </>

    )
}
export default Network