import { useContext} from "react"
import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper , Card , Cards , CardTop , CardCenter , CardBottom } from "./styled-index"
import { VaucherContext } from "../../../context/admin-panel/Vaucher/context"

function AdminVaucher() {
    const {VaucherMap} = useContext(VaucherContext)
    return(
        <>
        <Wrapper>
            <DashboardMain>
                <h2>ЗАявка по ваучеру</h2>
                <Cards>
                {VaucherMap.map((elem) =>
                    <Card>
                        <CardTop>
                        <span>{elem.name}</span>
                        </CardTop>
                        <CardCenter>
                       <span>{elem.comment}</span>
                        </CardCenter>
                    <CardBottom>
                       <span>+{elem.phonenumber}</span>
                    </CardBottom>
                    </Card>
                        )}
                </Cards>
            </DashboardMain>
        </Wrapper>
        </>
    )
}
export default AdminVaucher