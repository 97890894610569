import styled from "styled-components";

export const CardWorker = styled.div`
    video{
        margin: 0;
        padding: 0;
        border-radius: 10px;
        border: 5px solid transparent;
    }
    video::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: red;
    }
    button{
        font-family: 'Inter';
        position: absolute;
        display: flex;
        margin-left: 15px;
        margin-top: 15px;
        background-color: #FFD700;
        font-size: 16px;
        padding: 10px;
        border-radius: 30px;
        cursor: pointer;
        z-index: 1;
    }
    button:nth-child(2){
        display: none;
    }
    span{
        font-family: 'Inter';
        position: absolute;
        display: flex;
        margin-top: -55px;
        margin-left: 10px;
        background-color: #FFD700;
        padding: 10px;
        font-size: 18px;
    }
    @media only screen and (max-width :768px) {
        flex-direction: column;
        width: 45.6%;
        text-align: center;
        height: 345px;
        img{
            width: 100%;
            height: 236px;
        }
    }
    @media only screen and (max-width : 593px) {
        flex-direction: column;
        width: 45.5%;
        text-align: center;
        height: 345px;
        img{
            width: 100%;
            height: 236px;
        }
    }
    @media only screen and (max-width : 517px) {
        flex-direction: column;
        width: 45.5%;
        text-align: center;
        height: 345px;
        padding: 7.1px;
        img{
            width: 100%;
            height: 236px;
        }
    }
`