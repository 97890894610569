import { useRef, useState } from "react";
import {Wrapper , ModalTop , Form} from "./styled-index"
import ModalCommon from "../../common/modal";
import axios from "axios";
function ReviewsModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const Title = useRef();
    const handleClose = () => setOpen(false);
    const [videoSelect , setvideoSelect] = useState()
    const [loading , setLoading] = useState(false)
    const HandleChange = (e) =>{
        const formData = new FormData()
        formData.append("file" , e.target.files[0])
        formData.append("upload_preset" , "v8gxbibt")
        setLoading(true)
        const postImage = async () =>{
            try{
                const response = await axios.post("https://api.cloudinary.com/v1_1/dugfn5qgj/upload" , formData)
                setvideoSelect(response?.data.secure_url)
                setLoading(false)
            }catch(error){
                console.error(error);
            }
        }
        postImage()
}
    const HandleSubmit = async (e) =>{
        e.preventDefault()
        try {
           await fetch('https://api.supersite.uz/Reviews', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    video :  videoSelect, 
                    title :  Title.current.value, 

                })
            })
            .then(res => res.text())
            .then(result =>  console.log(result));
            e.target[0].value = null
            e.target[1].value = null
            window.location.reload()

          } catch(err) {
            console.log(err)
        }
    }

    return(
        <Wrapper>
        <button onClick={handleOpen}>Добавить ОТЗЫВЫ</button>

        <ModalCommon handleClose={handleClose} open={open} >
            <ModalTop>
                    <span>Добавить ОТЗЫВЫ </span>
                    <span onClick={handleClose}>&times;</span>
            </ModalTop>
            <Form className="form" onSubmit={HandleSubmit}>
            <input type="file" id="file" onChange={HandleChange}/>
                <label for="file" class="custom-file-upload">
                    <span className="span-download"><ion-icon  name="cloud-download-outline"></ion-icon></span>
                загрузить изображение
                </label>
                {loading ? (<>
                    <span className="loading">loading...</span>
                </>):null}
                <input ref={Title} type="text"  placeholder="Название компании" required />
                <button type="submit">Сохранять</button>
            </Form>
        </ModalCommon>
        </Wrapper>
    )
}
export default ReviewsModal