import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 360px;
    h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 200px;
        line-height: 10px;
        text-transform: uppercase;
        color: rgba(42, 42, 42, 0.48);
        position: relative;
        margin-left: 300px;
        margin-right: 300px;
        margin-top: 150px;
    }
    @media only screen and (max-width:1024px) {
        h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 150px;
        line-height: 10px;
        text-transform: uppercase;
        color: rgba(42, 42, 42, 0.48);
        position: relative;
        margin-left: 150px;
        margin-right: 150px;
        margin-top: 100px;

    }
    }
    @media only screen and (max-width:860px) {
        width: 100%;
    height: 260px;

        h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 120px;
        line-height: 10px;
        text-transform: uppercase;
        color: rgba(42, 42, 42, 0.48);
        position: relative;
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 50px;

    }
    }
    @media only screen and (max-width:860px) {
        width: 100%;
        height:160px;
        h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 80px;
        line-height: 10px;
        text-transform: uppercase;
        color: rgba(42, 42, 42, 0.48);
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
    }
    }
    @media only screen and (max-width:860px) {
        width: 100%;
        height:120px;
        h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 60px;
        line-height: 10px;
        text-transform: uppercase;
        color: rgba(42, 42, 42, 0.48);
        position: relative;
        margin-left: 30px;
        margin-right:30px;
    }
    }
    .slick-next{
        display: none !important;
    }
`