import { Wrapper } from "./styled-index";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext } from "react";
import { BlogContext } from "../../context/blog/context";
import { useTranslation } from "react-i18next";

function BlogCompnonent() {
  const { BlogMap } = useContext(BlogContext);
  const { t, i18n } = useTranslation();
  function GetValue() {
    return window.localStorage.getItem("i18nextLng");
  }

  return (
    <Wrapper>
      {BlogMap.map((elem) => (
        <>
          {GetValue() === "uz" ? (
            <Accordion
              key={elem.id}
              defaultExpanded={true}
              className="Acardion"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>{elem.titleuz}</h3>
              </AccordionSummary>
              <AccordionDetails>
                <p>{elem.descriptionuz}</p>
              </AccordionDetails>
            </Accordion>
          ) : GetValue() === "ru" ? (
            <Accordion defaultExpanded={true} className="Acardion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>{elem.titleru}</h3>
              </AccordionSummary>
              <AccordionDetails>
                <p style={{ marginBottom: "20px" }}>{elem.descriptionru}</p>
              </AccordionDetails>
            </Accordion>
          ) : GetValue() === "en" ? (
            <Accordion defaultExpanded={true} className="Acardion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>{elem.titleen}</h3>
              </AccordionSummary>
              <AccordionDetails>
                <p>{elem.descriptionen}</p>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </>
      ))}
    </Wrapper>
  );
}
export default BlogCompnonent;
