import { useContext} from "react"
import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper , Card , Cards , CardTop , CardCenter , CardBottom } from "./styled-index"
import { ConnectContext } from "../../../context/admin-panel/connect/context"
function AdminConnect() {
    const {ConnectMap} = useContext(ConnectContext)
    return(
        <>
        <Wrapper>
            <DashboardMain>
                <h2>Cвязаться с нами</h2>
                <Cards>
                {ConnectMap.map((elem) =>
                    <Card>
                        <CardTop>
                        <span>{elem.name}</span>
                        </CardTop>
                        <CardCenter>
                       <span>{elem.comment}</span>
                        </CardCenter>
                    <CardBottom>
                       <span>{elem.phonenumber}</span>
                    </CardBottom>
                    </Card>
                        )}
                </Cards>
            </DashboardMain>
        </Wrapper>
        </>
    )
}
export default AdminConnect