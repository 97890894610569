import styled from "styled-components";
export const Networks = styled.div`
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 186px;
    height: 222px;
    z-index: 1;
    @media only screen and (max-width : 1200px) {
        display: none;
    }
`