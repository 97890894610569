import { useTranslation } from "react-i18next"
import { Wrapper , Content , ContentTop  } from "./styled-index"

function Maps() {
    const {t , i18n} = useTranslation()
    return(
        <Wrapper>
        <Content>
            <h2>{t("Maps.0")}</h2>
            <ContentTop>
                <h3>{t("Maps.1")}</h3>
                <p>{t("Maps.2")}</p>
            </ContentTop>
            <ContentTop>
            <h3>{t("Maps.3")}</h3>
                <p>{t("Maps.4")}</p>
            </ContentTop>
            <ContentTop>
            <h3>{t("Maps.5")}</h3>
                <p>{t("Maps.6")}</p>
            </ContentTop>
        </Content>
     <iframe width="744" height="569" id="gmap_canvas" src="https://maps.google.com/maps?q=supersite.uz&t=&z=19&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </Wrapper>
    )
}
export default Maps