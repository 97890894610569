import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 1400px;
    width: 40%;
    margin: 0 auto;
    @media only screen and (max-width:768px) {
    width: 100%;
    }
`
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.9;
  cursor: pointer;
  z-index: 1;
`
export const ModalWrapper = styled.div`
    border-radius: 7px;
    position: absolute;
    text-align: center;
    width: 435px;
    height: 293px;
    background-color: #1B1B1B;
    z-index: 10;
    padding: 34px 26px;
    box-shadow: 0px 0px 10px 5px black;
    @media only screen and (max-width:517px) {
    width: 75%;
    }
    @media only screen and (max-width:425px) {
    width: 70%;
    }
    button{
        background: #FFD700;
        border-radius: 7px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #1B1B1B;
        padding: 13px 30px;
        cursor: pointer;
        }
`