import { useTranslation } from "react-i18next"
import {Wrapper , Container} from "./styled-index"

function HomeAbout() {
    const [t , i18n] = useTranslation();
    return(
        <Wrapper>
            <Container>
            <h3>{t("About.0")}</h3>
            <ul>
                <li>
                    <h4>6</h4>
                    <span>{t("About.1")}</span>
                </li>
                <li>
                    <h4>120</h4>
                    <span>{t("About.2")}</span>
                </li>
                <li>
                    <h4>32</h4>
                    <span>{t("About.3")}</span>
                </li>
                <li>
                    <h4>78</h4>
                    <span>{t("About.4")}</span>
                </li>
            </ul>
            </Container>
        </Wrapper>
    )
}
export default HomeAbout