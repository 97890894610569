import { Overlay , ModalWrapper, Wrapper } from "./styled-index"
import SuccessIcon from "./../../../../assets/component/icons8-checkmark.svg"
import { useTranslation } from "react-i18next"

function Modal({setModals}) {
    const {t , i18n} = useTranslation(); 
    return(
        <>
        <Wrapper>
        <ModalWrapper>
            <img src={SuccessIcon} width={87} height={87} alt="success icon" />
            <p>{t("Success.0")}</p>
            <button onClick={setModals}>OK</button>
        </ModalWrapper>
        </Wrapper>
        <Overlay onClick={setModals}>
        </Overlay>
        </>
    )
}
export default Modal