import { useContext } from "react"
import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper } from "../../../component/admin-panel/dashboard-main/styled-index"
import { ClientContext } from "../../../context/admin-panel/client/context";
import { Background , Container } from "./styled-index";
import ClientModal from "../../../component/admin-panel/client/modal-form";
import ClientDeletPutComponent from "../../../component/admin-panel/client/client-delet-put";
function AdminClient() {
    const { ClientMap} = useContext(ClientContext);
    return(
        <>
            <Wrapper>
            <DashboardMain>
                <ClientModal/>
                <Container>
                {ClientMap.map(elem =>  
                    <Background>
                    <img src={elem.logoimage} width={172} height={152} alt="img logo" />
                        <ClientDeletPutComponent DeleteId={elem.id} PutBlog={elem.id}/>
                    </Background>
    )}
                </Container>
            </DashboardMain>
        </Wrapper>
        </>
    )
}
export default AdminClient