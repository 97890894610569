import { useContext } from "react"
import { PartnersContext } from "../../../context/partners/context"
import { Wrapper  , Images} from "./styled-index"
import React, { Component } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function Clients() {
    const {PartnersMap} = useContext(PartnersContext)
    const {t , i18n} = useTranslation();
    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
      };
    return(
        <Wrapper>
            <h3>{t("About.7")}</h3>

                <Slider {...settings}>

{PartnersMap.slice(1 , 8).map((elem) => (
    <Images>
        <img src={elem.logoimage}  width={270} height={170} alt="img logo" />
    </Images>
))}

    </Slider>
    <Slider {...settings}>

{PartnersMap.slice(9).map((elem) => (
    <Images>
        <img src={elem.logoimage} width={270} height={170} alt="img logo" />
    </Images>
))}

    </Slider>
        </Wrapper>


        
    )
}
export default Clients