import { useState , useEffect } from "react";
import { createContext } from "react";

export const TeamContext = createContext({
    TeamMap:[],
})

export const TeamProviderAdmin = ({children})=>{
    const [TeamMap , setTeamMap] = useState([])

    useEffect(() => {
        fetch("https://api.supersite.uz/Workers",{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
      
          })
        .then(res => res.json())
        .then(result => setTeamMap(result))
    }, [])

    const value = { TeamMap};
    return(
        <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
    )
}