import { useContext, useRef } from "react"
import { ReviewsContext } from "../../context/reviews/context";
import { TeamContext } from "../../context/team/context"
import CardReviews from "./cardWorker";
import { Wrapper ,Card , Left,Cards , CardWorker } from "./styled-index"


function ReviewsComponent() {
    const {ReviewsMap} = useContext(ReviewsContext);
    return(
        <Wrapper>
                <Cards>
                {ReviewsMap.map(elem => 
                    <CardReviews elem={elem} key={elem.id}/>
                )}
                </Cards>
        </Wrapper>
    )
}
export default ReviewsComponent