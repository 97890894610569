import styled from "styled-components";

export const Section = styled.div`
    width: 100%;
    background-color: #2A2A2A;
    position: fixed;
    z-index: 2;
    top: 0;
    .overlay{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0e0d0d;
        opacity: 0.7;
        z-index: 1;
        cursor: pointer;
}
.ice-header{
    width: 100%;
    height: 70px;
}
`
export const Wrapper = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 10px;
    .logo{
        margin-top: 5px;
    }
    @media only screen and (max-width: 768px) {
        width: 98%;
        margin: 0 auto;
    }
    @media only screen and (max-width: 426px) {
        width: 98%;
        margin: 0 auto;
        .logo-media{
            display: block !important;
            margin-top: 5px;
        }
        .logo{
            display: none;
        }

    }
    .logo-media{
            display: none;
        }
`
export const Headers = styled.div`
    display: flex;
    justify-content: space-between;
    ul{
        margin: 0;
        padding: 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 659px;
    }
    ul li {
        list-style-type: none;
        cursor: pointer;
    }
    ul li a{
        transition: 0.3s ;

    }
    ul li a:hover {
        text-shadow: 2px 2px 20px;
        transition: 0.3s ;
    }
    hr{
        height: 20px;
        border: transparent;
        width: 0.5px;
        background-color:#737373;
        height: 100%;
        opacity: 0.3;
    }
    ul li a{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-transform: uppercase;
        color: #FFFFFF;
        text-decoration: none;
    }
    @media only screen and (max-width: 768px) {
        ul{
            display: none;
    }

}
`