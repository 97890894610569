import NavLinkCommon from "../../common/link"
import {Section , Wrapper} from "./styled-index"
import { useTranslation } from "react-i18next"
import MovingComponent from 'react-moving-text'
function HomeHero() {
    const [t , i18n] = useTranslation();
    return(
        <Section>
            <Wrapper>
                <MovingComponent
  type="fadeInFromBottom"
  duration="2000ms"
  delay="0s"
  direction="normal"
  timing="ease"
  iteration="1"
  fillMode="none">
                <h1>{t("Home-hero.0")}<span>{t("Home-hero.1")}</span></h1>
</MovingComponent>
<MovingComponent
  type="fadeInFromBottom"
  duration="3000ms"
  delay="0s"
  direction="normal"
  timing="ease"
  iteration="1"
  fillMode="none">
                <p>{t("Home-hero.5")}<span>{t("Home-hero.3")}</span>  {t("Home-hero.2")} <br />   {t("Home-hero.6")} </p>
                <NavLinkCommon  padding={"23px 42px"} href="#form" title={t("Home-hero.4")} Link={false}/>
</MovingComponent>
            </Wrapper>
        </Section>
    )
}
export default HomeHero