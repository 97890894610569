import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0  auto;
    display: flex;
    justify-content: space-between;
    margin-top: 288px;
    @media only screen and (max-width: 1200px) {
        width: 95%;
    }
    @media only screen and (max-width: 768px) {
        margin-top: 68px;
        flex-direction: column;
        align-items: center;
        text-align: center;
}
`
export const Left =  styled.div`
    h3{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 80px;
        line-height: 78px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 24px;
        margin-top: 80px;
    }
    span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #FFD700;
    }
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        h3{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 38px;

        margin-bottom: 14px;
        margin-top: 40px;
    }
    span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #FFD700;
    }
}
`