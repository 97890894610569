import { useContext } from "react"
import { TeamContext } from "../../../../context/admin-panel/team/context";
import {Wrapper} from "./styled-index"
import { SitesContext } from "../../../../context/admin-panel/sites/context";
import { ClientContext } from "../../../../context/admin-panel/client/context";
import { ConnectContext } from "../../../../context/admin-panel/connect/context";

function CardBox() {
    const {TeamMap} = useContext(TeamContext);
    const {SitesMap} = useContext(SitesContext);
    const {ClientMap} = useContext(ClientContext)
    const {ConnectMap} = useContext(ConnectContext)
    return(
        <Wrapper>
        <div class="cardBox">
                <div class="card">
                    <div>
                        <div class="numbers">{TeamMap.length}</div>
                        <div class="cardName">Команда</div>
                    </div>

                    <div class="iconBx">
                        <ion-icon name="eye-outline"></ion-icon>
                    </div>
                </div>

                <div class="card">
                    <div>
                        <div class="numbers">{SitesMap.length}</div>
                        <div class="cardName">Наши работы</div>
                    </div>

                    <div class="iconBx">
                        <ion-icon name="cart-outline"></ion-icon>
                    </div>
                </div>

                <div class="card">
                    <div>
                        <div class="numbers">{ClientMap.length}</div>
                        <div class="cardName">Наши клиенты</div>
                    </div>

                    <div class="iconBx">
                        <ion-icon name="chatbubbles-outline"></ion-icon>
                    </div>
                </div>

                <div class="card">
                    <div>
                        <div class="numbers">{ConnectMap.length}</div>
                        <div class="cardName">Связаться с нами</div>
                    </div>

                    <div class="iconBx">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default CardBox