import { useState , useEffect } from "react";
import { createContext } from "react";

export const PartnersContext = createContext({
    PartnersMap:[],
})

export const PartnersProvider = ({children})=>{
    const [PartnersMap , setPartnersMap] = useState([])

    useEffect(() => {
        fetch("https://api.supersite.uz/partners",{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
      
          })
        .then(res => res.json())
        .then(result => setPartnersMap(result))
    }, [])

    const value = { PartnersMap};
    return(
        <PartnersContext.Provider value={value}>{children}</PartnersContext.Provider>
    )
}