import { Wrapper } from "./styled-index"
import Slider from "react-slick";
function CompanyName() {
    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 2000,
        cssEase: "linear"
      };
    return(
        <Wrapper>
            <Slider {...settings}>
            <h3>SUPERSITE</h3>
            <h3>SUPERSITE</h3>
            <h3>SUPERSITE</h3>
            <h3>SUPERSITE</h3>

            </Slider>
        </Wrapper>
    )
}
export default CompanyName