import './App.css';
// import SnowFall from './component/SnowFall';
import RoutesPage from './Routers/Router';

function App() {

  return (
    <>
      <RoutesPage/>
      {/* <SnowFall/> */}
    </>
  );
}

export default App;
