import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    margin-top: 168px;
    @media only screen and (max-width : 1052px) {
        width: 96%;
    }
`
export const Card = styled.div`
    display: flex;
    padding: 11px;
    border: 1px solid #525252;
    width: 914px;

    @media only screen and (max-width : 1052px) {
        width: 96%;
    }
    @media only screen and (max-width : 872px) {
        flex-direction: column;
        width: 94%;
        text-align: center;
        img{
            width: 100%;
        }
    }
    @media only screen and (max-width : 593px) {
        height: 407px;
    }
`
export const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
`
export const Left = styled.div`
    padding: 11px 28px;
    h4{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 58px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;  
        color: #FFD700;
    }
    @media only screen and (max-width : 593px) {
        h4{
        font-size: 18px;
        line-height: 58px;
    }
    span{
        font-size: 15px;
        line-height: 22px;  
    }
    }
    @media only screen and (max-width : 385px) {
        h4{
        font-size: 15px;
        line-height: 38px;
    }
    span{
        font-size: 12px;
        line-height: 22px;  
    }
    }
`
export const CardWorker = styled.div`
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 30px;
    @media only screen and (max-width : 768px) {
        width: 100%;
        margin-bottom:120px;
    }
    video{
        margin: 0;
        padding: 0;
        border-radius: 10px;
        border: 5px solid transparent;
    }
    video::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: red;
    }
    button{
        font-family: 'Inter';
        position: absolute;
        display: flex;
        margin-left: 15px;
        margin-top: 15px;
        background-color: #FFD700;
        font-size: 16px;
        padding: 10px;
        border-radius: 30px;
        cursor: pointer;
        z-index: 1;
    }
    button:nth-child(2){
        display: none;
    }
    span{
        font-family: 'Inter';
        position: absolute;
        display: flex;
        margin-top: -55px;
        margin-left: 10px;
        background-color: #FFD700;
        padding: 10px;
        font-size: 18px;
    }
    @media only screen and (max-width :768px) {
        flex-direction: column;
        width: 45.6%;
        text-align: center;
        height: 345px;
        img{
            width: 100%;
            height: 236px;
        }
    }
    @media only screen and (max-width : 593px) {
        flex-direction: column;
        width: 45.5%;
        text-align: center;
        height: 345px;
        img{
            width: 100%;
            height: 236px;
        }
    }
    @media only screen and (max-width : 517px) {
        flex-direction: column;
        width: 45.5%;
        text-align: center;
        height: 345px;
        padding: 7.1px;
        img{
            width: 100%;
            height: 236px;
        }
    }
`