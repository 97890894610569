import { useState , useEffect } from "react";
import { createContext } from "react";

export const BlogContext = createContext({
    BlogMap:[],
})

export const BlogProvider = ({children})=>{
    const [BlogMap , setBlogMap] = useState([])
    useEffect(() => {
        fetch("https://api.supersite.uz/Blog",{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
      
          })
        .then(res => res.json())
        .then(result => setBlogMap(result))
    }, [])

    const value = { BlogMap};
    return(
        <BlogContext.Provider value={value}>{children}</BlogContext.Provider>
    )
}