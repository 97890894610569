import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReviewsContext } from "../../../context/reviews/context";
import { CardWorker } from "../styled-index";

function CardReviews({elem}) {
    const videoRef = useRef();
    const videoStop = useRef();
    const videoPlay = useRef();
    const {t , i18n} = useTranslation();
    const handlePlay = () =>{
        videoRef.current.play();
        videoRef.current.style.border = "5px solid #FFD700"
        videoPlay.current.style.display ="none"
        videoStop.current.style.display ="flex"

    }
    const handleStop = () =>{
        videoRef.current.pause();
        videoRef.current.style.border = "5px solid transparent"
        videoPlay.current.style.display ="flex"
        videoStop.current.style.display ="none"
    }
    return(
        <CardWorker>
        <button ref={videoPlay} onClick={() => handlePlay()}><ion-icon name="play-forward-circle-outline"></ion-icon> {t("Reviews.0")} </button>
        <button ref={videoStop} onClick={() => handleStop()}><ion-icon name="pause-circle-outline"></ion-icon> {t("Reviews.1")}</button>
        <video id={elem.id} width={247} height={440} ref={videoRef} >
            <source src={elem.video} alt="video" />
        </video>
        <span> {elem.title}</span>
    </CardWorker>
    )
}
export default CardReviews