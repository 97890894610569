import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    margin-top: 172px;
    .slick-arrow{
        display: none !important;
    }
    @media only screen and (max-width: 1200px) {
    width: 95%;
    }   
    @media only screen and (max-width: 383px) {
    width: 95%;
        img{
            width: 90px;
            height: 75px;
        }
    }  
    h3{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 69px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 60px;
    }
`
export const Images = styled.div`
    width: 98% !important;
    height: 170px;
    background-color: #333333;
    @media only screen and (max-width: 383px) {
        height: 90px;
    }  
`