import React from "react";
import {Wrapper} from "./styled-index"
import ImageIcon from "./../../assets/bxl-telegram.svg"
import ImageIcon2 from "./../../assets/bxs-phone-call.svg"

const FixedAnime = () => {
  return (
    <>
      <Wrapper>
        <a href="https://t.me/Supersite_uz">
        <img src={ImageIcon} alt="" />
        </a>
        <a href="tel:+99899 810-70-90">
        <img src={ImageIcon2} alt="" />
        </a>
      </Wrapper>
    </>
  );
};

export default FixedAnime;
