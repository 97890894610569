import Portfolio from "../../component/portfolio"
import { Section , Wrapper , Wrappers } from "./index-styled"
import AcardionReklama from "../../component/home/acardion-reklama"
import Header from "../../component/header"
import Footer from "../../component/footer"
import Network from "../../component/networks"
import { useTranslation } from "react-i18next"
import FixedAnime from "../../component/fixedAnime"
function Projects() {
    const {t , i18n} = useTranslation();
    return(
            <>
            <Header/>
            <Network/>
                    <Wrappers>
        <Section>
            <Wrapper>
                <h1><span>{t("Projects.0")}</span></h1>
            </Wrapper>
        </Section>
            <AcardionReklama/>
            <Portfolio/>
        </Wrappers>
        <Footer/>
        <FixedAnime/>

            </>
    )
}
export default Projects