import {Wrapper , WrapperLeft} from "./styled-index"
import ImageAvatar from "./../../assets/about/about-image.png"
import { useTranslation } from "react-i18next";
function AboutComponent() {
    const {t ,i18n} = useTranslation();
    return(
        <Wrapper>
            <WrapperLeft>
                <h2>{t("About.0")}</h2>
                <p>{t("AboutPage.0")}</p>
                <span>{t("AboutPage.1")}</span>
            </WrapperLeft>
            <img src={ImageAvatar} width={472} height={534} alt="Avatar img" />
        </Wrapper>
    )
}
export default AboutComponent