import { useContext } from "react"
import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper } from "../../../component/admin-panel/dashboard-main/styled-index"
import { TeamContext } from "../../../context/admin-panel/team/context"
import { Container } from "./styled-index"
import TeamModal from "../../../component/admin-panel/team/modal-form"
import TeamDeletPutComponent from "../../../component/admin-panel/team/team-delet-put"
function AdminTeam() {
    const { TeamMap }  = useContext(TeamContext)
    return(
        <>
          <Wrapper>
            <DashboardMain>
                <TeamModal/>
                <Container>
                <table id="table">
                    <thead id="thead">
                      <th id="th">Изображение работника <span>Имя</span></th>
                      <th>должность</th>
                      <th id="th">Изменить Удалить</th>
                    </thead>
                    <tbody id="tbody">
                        {TeamMap.map((elem) =>
                      <tr>
                        <td><img src={elem.imageavatar} width={48} height={48} alt="img avatar" /> <span>{elem.workername}</span></td>
                          <td>{elem.workerposition}</td>
                        <td><TeamDeletPutComponent Element={elem} DeleteId={elem.id} PutBlog={elem.id}/></td>
                     </tr>
                        )}
                    </tbody>
                   </table>
                </Container>
            </DashboardMain>
        </Wrapper>
        </>
    )
}
export default AdminTeam