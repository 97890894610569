import { useContext } from "react"
import DashboardMain from "../../../component/admin-panel/dashboard-main"
import { Wrapper} from "../../../component/admin-panel/dashboard-main/styled-index"
import { SitesContext } from "../../../context/admin-panel/sites/context"
import { Container} from "./styled-index"
import ProjectModal from "../../../component/admin-panel/projects/modal-form"
import ProjectsDeletePutComponent from "../../../component/admin-panel/projects/team-delet-put"

function AdminProject() {
    const {SitesMap } = useContext(SitesContext)
    return(
        <>
                    <Wrapper>
            <DashboardMain>
                <ProjectModal/>
            <Container>
            <ul>
            {SitesMap.map((elem) =>(
                <li>
                    <a href={elem.link} target="_blank">
                    <img className="image" src={elem.background} width={558} height={304} alt="" />
                    <span>{elem.title}</span>
                    </a>
                    <ProjectsDeletePutComponent DeleteId={elem.id} Element={elem} PutBlog={elem.id}/>
                </li>
            ))}
            </ul>
        </Container>
            </DashboardMain>
        </Wrapper>
        </>
    )
}
export default AdminProject