import { useRef} from "react";
import {Wrapper , ModalTop , Form} from "./styled-index"
import ModalCommon from "../../../common/modal";
function ModalPut({handleClose , open , PutBlog , Title , Element}) {
    const TitleRu = useRef();
    const DescriptionRu  = useRef();
    const TitleUz = useRef();
    const DescriptionUz  = useRef();
    const TitleEn = useRef();
    const DescriptionEn  = useRef();
    const HandleSubmit = async (e , id ) =>{
        e.preventDefault()
        try {
           await fetch(`https://api.supersite.uz/Blog/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    TitleRu :  TitleRu.current.value, 
                    DescriptionRu : DescriptionRu.current.value,
                    TitleUz :  TitleUz.current.value, 
                    DescriptionUz : DescriptionUz.current.value,
                    TitleEn :  TitleEn.current.value, 
                    DescriptionEn : DescriptionEn.current.value,
                })
            })
            .then(res => res.text())
            .then(result => console.log(result));
            e.target[0].value = null
            e.target[1].value = null
            e.target[2].value = null
            e.target[3].value = null
            e.target[4].value = null
            e.target[5].value = null
            window.location.reload()

          } catch(err) {
            console.log(err)
        }
    }
    return(
        <Wrapper>
        <ModalCommon handleClose={handleClose} open={open} scroll="scroll" height="400px">
            <ModalTop>
                    <span>Добавить Блог</span>
                    <span onClick={handleClose}>&times;</span>
            </ModalTop>
            <Form className="form" onSubmit={(e) => HandleSubmit(e , PutBlog)}>
                 <p>Русский языке</p>
                <input ref={TitleRu} type="text"  placeholder={Element.TitleRu} required />
                <textarea  ref={DescriptionRu} type="text" placeholder={Element.DescriptionRu} required />
                <p>Узбекском языке</p>
                <input ref={TitleUz} type="text" placeholder={Element.TitleUz} required />
                <textarea  ref={DescriptionUz} type="text" placeholder={Element.DescriptionUz} required />
                <p>Английский язык</p>
                <input ref={TitleEn} type="text" placeholder={Element.TitleEn} required />
                <textarea  ref={DescriptionEn} type="text" placeholder={Element.DescriptionEn} required />
                <button type="submit">Сохранять</button>
            </Form>
        </ModalCommon>
        </Wrapper>
    )
}
export default ModalPut