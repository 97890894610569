import { useState , useEffect } from "react";
import { createContext } from "react";

export const SitesContext = createContext({
    SitesMap:[],
})

export const SitesProviderAdmin = ({children})=>{
    const [SitesMap , setSitesMap] = useState([])

    useEffect(() => {
        fetch("https://api.supersite.uz/sites",{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
      
          })
        .then(res => res.json())
        .then(result => setSitesMap(result))
    }, [])

    const value = { SitesMap};
    return(
        <SitesContext.Provider value={value}>{children}</SitesContext.Provider>
    )
}