import LanguageHeader from "./header-language"
import {Wrapper , Headers , Section} from "./styled-index"
import Logo  from "./../../assets/component/logo.svg"
import LogoMedia  from "./../../assets/component/logo-media.svg"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import HamburgerMenu from "./hamburger-menu/index"
import { useState } from "react"
import HeaderIce from "./../../assets/ice-header.png"
function Header() {
    const {t , i18n} = useTranslation()
    const [HamburgerClick , setHamburgerClick] = useState(false)
    const HandleClick = () =>{
        setHamburgerClick(true)
      }
      const HandleClickClose = () =>{
        setHamburgerClick(false)
      }
    const data = [
        {
            id : 1, 
            title : "Header.0",
            link : "/services",

        },
        {
            id : 2, 
            title : "Header.1",
            link : "/projects",

        },
        {
            id : 3, 
            title : "Header.2",
            link : "/about",

        },
        {
            id : 4, 
            title : "Header.3",
            link : "/team",

        },
        {
            id : 5, 
            title : "Header.4",
            link : "/Blog",
        },
        {
            id : 5, 
            title : "Header.5",
            link : "/reviews",
        }, 
    ]
    return(
        <>
                <Section>
        <Wrapper>
           <Headers>
                
                <NavLink to={"/"}><img className="logo"  src={Logo} width={179} height={51} alt="Logo" /></NavLink>
                <NavLink to={"/"}><img className="logo-media"  src={LogoMedia} width={44} height={44} alt="Logo" /></NavLink>
                <ul>
                    {data.map(elem =>(
                        <>
                    <li key={elem.id}>
                      <NavLink  to={elem.link} ><a >
                      {t(elem.title)}</a></NavLink>
                    </li> 
                    <hr />
                    </>
                    ))}
                </ul>
            <LanguageHeader HandleClick={HandleClick}/>
            </Headers> 
        </Wrapper>
                        {HamburgerClick === false ? (
                                    <div className="overlay" ></div>
                        ) : HamburgerClick === true ? (
                            <div className="overlay" style={{display : "block"}} onClick={HandleClickClose}></div>

                        ):null}
        </Section>
        
        <HamburgerMenu HamburgerClick={HamburgerClick} HandleClickClose={HandleClickClose}/>
        </>

    )
}
export default Header