import { useRef, useState } from "react";
import {Wrapper , ModalTop , Form} from "./styled-index"
import ModalCommon from "../../common/modal";
import axios from "axios";
function ProjectModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedImages , setSelectedImages] = useState()
    const [loading , setLoading] = useState(false)
    const ImageText = useRef();
    const ImageLink = useRef();
    const HandleSubmit = async (e) =>{
        e.preventDefault()
        try {
           await fetch('https://api.supersite.uz/sites', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title :  ImageText.current.value, 
                    link : ImageLink.current.value,
                    Background :  selectedImages, 
                })
            })
            .then(res => res.text())
            .then(result => console.log(result));
            e.target[0].value = null
            e.target[1].value = null
            e.target[2].value = null
            window.location.reload()

            handleClose()
          } catch(err) {
            console.log(err)
        }
    }

    const HandleChange = (e) =>{
        const formData = new FormData()
        formData.append("file" , e.target.files[0])
        formData.append("upload_preset" , "v8gxbibt")
        setLoading(true)
        const postImage = async () =>{
            try{
                const response = await axios.post("https://api.cloudinary.com/v1_1/dugfn5qgj/upload" , formData)
                setSelectedImages(response?.data.secure_url)
                setLoading(false)
            }catch(error){
                console.error(error);
            }
        }
        postImage()
}
    return(
        <Wrapper>
        <button onClick={handleOpen}>Добавить работы</button>
        <ModalCommon handleClose={handleClose} open={open} >
            <ModalTop>
                    <span>Добавить работы </span>
                    <span onClick={handleClose}>&times;</span>
            </ModalTop>
            <Form className="form" onSubmit={HandleSubmit}>
            <input type="file" id="file" onChange={HandleChange}/>
                <label for="file" class="custom-file-upload">
                    <span className="span-download"><ion-icon  name="cloud-download-outline"></ion-icon></span>
                загрузить изображение
                </label>
                {loading ? (<>
                    <span className="loading">loading...</span>
                </>):null}
                <input ref={ImageText} type="text" placeholder="название проекта" required />
                <input ref={ImageLink} type="text" placeholder="проекта линк" required />
                <button type="submit">Сохранять</button>
            </Form>
        </ModalCommon>
        </Wrapper>
    )
}
export default ProjectModal