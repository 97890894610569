import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18next'
import App from './App';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import { SitesProvider } from './context/sites/context';
import { PartnersProvider } from './context/partners/context';
import { BlogProvider } from './context/blog/context';
import { TeamProvider } from './context/team/context';
import { ReviewsProvider } from './context/reviews/context';
import { AdminProviderAdmin } from './context/admin-panel/admin/context';
import { BlogProviderAdmin } from './context/admin-panel/Blog/context';
import { TeamProviderAdmin } from './context/admin-panel/team/context';
import { ConnectProviderAdmin } from './context/admin-panel/connect/context';
import { VaucherProviderAdmin } from './context/admin-panel/Vaucher/context';
import { ClientProviderAdmin } from './context/admin-panel/client/context';
import { SitesProviderAdmin } from './context/admin-panel/sites/context';
import { ReviewsProviderAdmin } from './context/admin-panel/video/context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <SitesProvider>
        <PartnersProvider>
          <BlogProvider>
            <TeamProvider>
              <ReviewsProvider>
             <AdminProviderAdmin>
                <BlogProviderAdmin>
                  <TeamProviderAdmin>
                    <ConnectProviderAdmin>
                      <VaucherProviderAdmin>
                        <ClientProviderAdmin>
                          <SitesProviderAdmin>
                            <ReviewsProviderAdmin>
                            <App />
                            </ReviewsProviderAdmin>
                          </SitesProviderAdmin>
                        </ClientProviderAdmin>
                      </VaucherProviderAdmin>
                    </ConnectProviderAdmin>
                  </TeamProviderAdmin>
                </BlogProviderAdmin>
              </AdminProviderAdmin>
              </ReviewsProvider>
            </TeamProvider>
          </BlogProvider>
        </PartnersProvider>
      </SitesProvider>
      </Suspense>
    </Router>

  </React.StrictMode>
);