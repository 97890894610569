import styled from "styled-components";

export const Wrapper = styled.div`
max-width: 1400px;
width: 100%;
margin: 0 auto;
display:flex ;
justify-content: space-between;
margin-top: 139px;
@media only screen and (max-width : 1200px) {
    width: 95%;
}
@media only screen and (max-width : 768px) {
    flex-direction: column;
    width: 95%;
    iframe{
        width: 100% !important;
    }
}
`
export const Content = styled.div`
h2{
    margin: 0; 
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 64px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 17px;
}
`
export const ContentTop = styled.div`
    width: 289px;
    h3{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 58px;
        text-transform: uppercase;
        color: #FFD700;
    }
    p{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #E5E5E5;
        margin-bottom: 28px;
    }
`