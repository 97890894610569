import { useState , useEffect } from "react";
import { createContext } from "react";

export const ClientContext = createContext({
    ClientMap:[],
})

export const ClientProviderAdmin = ({children})=>{
    const [ClientMap , setClientMap] = useState([])

    useEffect(() => {
        fetch("https://api.supersite.uz/partners",{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
      
          })
        .then(res => res.json())
        .then(result => setClientMap(result))
    }, [])

    const value = { ClientMap};
    return(
        <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
    )
}