import { useTranslation } from "react-i18next"
import { ReactPhoneInput, Wrapper } from "./styled-index"
import Button from "../../../common/button";
import { useRef } from "react";
import Modal from "../sucess-modal";
import { useState } from "react";
import 'react-phone-input-2/lib/style.css';
function RequestVaucher({control , nameInput}) {
    const {t , i18n} = useTranslation();
    const [Modals  , setModals] = useState(false);
    const name = useRef();
    const PhoneNumber = useRef();
    const Comment = useRef();
    const [value  , setValue] = useState()
    const [numberValue , setNumberValue] = useState();
    function GetValueLanguage() {
        return window.localStorage.getItem("i18nextLng")
    }
    const TOKEN = '5954602547:AAF3zGiBoAtjJhiQidR4M-61dLNBJ5WVjJE';
    const CHAT_ID = '-1001869295670';
    const HandleSubmit = (e) =>{
        e.preventDefault()
        const Name = name.current.value
        const comment = Comment.current.value
        const info = `ЗAЯВКА ПО ВАУЧЕРУ %0AИмя : ${Name} %0AНомер телефона: ${numberValue} %0Aкомментарии: ${comment} `
         fetch(`https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${info}&parse_mode=html`)
        fetch("https://api.supersite.uz/claimVoucher" , {
            method : "POST",
            headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                name : Name,
                PhoneNumber : numberValue ,
                Comment : comment ,
            })
        })  
        .then(res => res.json())
        .then(data =>  {
            if (data) {
                return setModals(true);
            }
        })
        e.target[0].value = null
        e.target[1].value = null
        e.target[2].value = null
    }

    return(
        <>
               {Modals === true ? <Modal setModals={() => setModals(false)}/> : null}
        <Wrapper>
            <p><span>{t("Form.2")} </span>{t("Form.7")}</p>
            <form onSubmit={HandleSubmit} >
                <input ref={name} type="text"  placeholder={t("Form.3")}  required/>
                {GetValueLanguage() == 'en' ?                 <ReactPhoneInput
            country={'ae'}
            name="Number"
            onChange={(data) => setNumberValue(data)}
          /> : <ReactPhoneInput
          country={'uz'}
          name="Number"
          onChange={(data) => setNumberValue(data)}
        />}
                <input ref={Comment} type="text" placeholder={t("Form.5")} required/>
                <Button padding="25px" title={t("Form.6")} type="submit"/>
            </form>
        </Wrapper>
        </>
    )
}
export default RequestVaucher