import { useContext } from "react"
import { TeamContext } from "../../context/team/context"
import { Wrapper ,Card , Left,Cards , CardWorker } from "./styled-index"


function TeamComponent() {
    const {TeamMap } = useContext(TeamContext);
    function GetLangValue() {
        return  window.localStorage.getItem("i18nextLng")   
      }
    return(
        <Wrapper>
            {TeamMap.slice(0 ,1).map(elem => 
                <Card>
                    <img src={elem.imageavatar} width={621} height={521} alt="Avatar Img" />
                    <Left>
                        <h4>{GetLangValue() == "ru" ? elem.workername :GetLangValue() == "uz" ? elem.workername_uz :GetLangValue() == "en" ? elem.workername_en :null}</h4>
                        <span>{GetLangValue() == "ru" ? elem.workerposition :GetLangValue() == "uz" ? elem.workerposition_uz :GetLangValue() == "en" ? elem.workerposition_en :null}</span>
                    </Left>
                </Card>
                )}
                <Cards>
                {TeamMap.slice(1).map(elem => 
                <CardWorker>
                    <img src={elem.imageavatar} width={320} height={472} alt="Avatar Img" />
                    <Left>
                    <h4>{GetLangValue() == "ru" ? elem.workername :GetLangValue() == "uz" ? elem.workername_uz :GetLangValue() == "en" ? elem.workername_en :null}</h4>
                        <span>{GetLangValue() == "ru" ? elem.workerposition :GetLangValue() == "uz" ? elem.workerposition_uz :GetLangValue() == "en" ? elem.workerposition_en :null}</span>
                    </Left>
                </CardWorker>
                )}
                </Cards>
        </Wrapper>
    )
}
export default TeamComponent