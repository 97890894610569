import { Section , Wrapper , Wrappers } from "./index-styled"
import BlogCompnonent from "../../component/blog"
import Maps from "../../component/map";
import { useTranslation } from "react-i18next"
import AcardionReklama from "../../component/home/acardion-reklama";
import Header from "../../component/header"
import Footer from "../../component/footer"
import Network from "../../component/networks"
import FixedAnime from "../../component/fixedAnime";
function Blog() {
    const {t , i18n} = useTranslation();
    return(
        <>
        <Header/>
        <Network/>
                <Wrappers>
        <Section>
            <Wrapper>
                <h1><span>{t("Blog.0")}</span></h1>
            </Wrapper>
        </Section>
        <AcardionReklama/>
            <BlogCompnonent/>
            <Maps/>
        </Wrappers>
        <Footer/>
        <FixedAnime/>

        </>
    )
}
export default Blog