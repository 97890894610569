import { NavLink } from "react-router-dom"
import {  Wrapper } from "./styled-index"
function NavLinkCommon({title , style , to  , Link   , padding  , href , target}) {
    
    return(
        <Wrapper>
            {Link === true ? (
        <NavLink className={"NavLink"} to={to} style={{padding :padding}}>{title}</NavLink>
    ) : null}
            {Link === false ? (
        <a className={"NavLink"} to={to} href={href} target={target} style={{padding :padding}}>{title}</a>
    ) : null}
        </Wrapper>
    )
}
export default NavLinkCommon