import styled from "styled-components";

export const ButtonActives = styled.div`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 69px;
        text-transform: uppercase;
        background-color: transparent;
        margin: 0;
        padding: 0;
        padding: 0px 59px;
        cursor: pointer;
        margin-bottom: 17px;
        @media only screen and (max-width : 501px) {
            font-size: 20px;
            text-align: center;
        line-height: 69px;
        padding: 0px 19px;
}
`

export const Wrapper = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    background-color: #222121;
    margin-top: 113px;
    p{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        width: 380px;
        color: white;
        margin-bottom: 40px;
    }
    p span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #FFD700;
    }
    @media only screen and (max-width : 501px) {
        p{
            width: 100%;
        }
}
`

export const Top = styled.div`
    display: flex;
    @media only screen and (max-width : 768px) {
    flex-direction: column;
    align-items: center;
}
`

export const WrapperForms = styled.div`
    padding: 47px 36px;
`