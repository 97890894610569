export const Data = [
    {
      id: 1,
      TitleCategoryRu: "Landing Page",
      TitleCategoryUz: "Landing Page",
      TitleCategoryEn: "Landing Page"
    },
    {
      id: 2,
      TitleCategoryRu: "Корпоративный сайт",
      TitleCategoryUz: "Korporativ veb-sayt",
      TitleCategoryEn: "Corporate website"
    },
    {
      id: 3,
      TitleCategoryRu: "Интернет магазин",
      TitleCategoryUz: "Online magazin ",
      TitleCategoryEn: "Online shop"
    },
    {
      id: 4,
      TitleCategoryRu: "Платформы",
      TitleCategoryUz: "Platformalar",
      TitleCategoryEn: "Platforms"
    },
    {
      id: 5,
      TitleCategoryRu: "Каталог iOS & Android",
      TitleCategoryUz: "iOS va Android katalogi",
      TitleCategoryEn: "iOS & Android catalog"
    },
    {
      id: 6,
      TitleCategoryRu: "Интернет магазин",
      TitleCategoryUz: "Internet shop",
      TitleCategoryEn: "Internet magazin"
    },
    {
      id: 7,
      TitleCategoryRu: "Индивидуальные приложения",
      TitleCategoryUz: "Shaxsiy ilovalar",
      TitleCategoryEn: "Individual Applications"
    },
    {
      id: 8,
      TitleCategoryRu: "Нeйминг, логотип",
      TitleCategoryUz: "Logotip, Nomlash",
      TitleCategoryEn: "Naming, logo"
    },
    {
      id: 9,
      TitleCategoryRu: "Брендбук",
      TitleCategoryUz: "Brendbook",
      TitleCategoryEn: "Brandbook"
    },
    {
      id: 10,
      TitleCategoryRu: "Бизнес планы",
      TitleCategoryUz: "Biznes rejalar",
      TitleCategoryEn: "Business plans"
    },
    {
      id: 11,
      TitleCategoryRu: "Презентации",
      TitleCategoryUz: "Taqdimotlar",
      TitleCategoryEn: "Presentations"
    },
    {
      id: 12,
      TitleCategoryRu: "Информационный",
      TitleCategoryUz: "Axborot",
      TitleCategoryEn: "Informational"
    },
    {
      id: 13,
      TitleCategoryRu: "Для онлайн продаж",
      TitleCategoryUz: "Onlayn savdo uchun",
      TitleCategoryEn: "For online sales"
    },
    {
      id: 14,
      TitleCategoryRu: "Сетевой ресторан",
      TitleCategoryUz: "Tarmoqli restoran",
      TitleCategoryEn: "Chain restaurant"
    },
    {
        id: 15,
        TitleCategoryRu: "SMM Старт",
        TitleCategoryUz: "SMM Start",
        TitleCategoryEn: "SMM Start"
      },
      {
        id: 16,
        TitleCategoryRu: "SMM Базовый, SMM Профи",
        TitleCategoryUz: "SMM Basic, SMM Pro",
        TitleCategoryEn: "SMM Basic, SMM Pro"
      },
      {
        id:17,
        TitleCategoryRu: "SEO оптимизация",
        TitleCategoryUz: "SEO optimallashtirish",
        TitleCategoryEn: "SEO optimization"
      },
      {
        id: 18,
        TitleCategoryRu: "Google топ 3",
        TitleCategoryUz: "Google top 3",
        TitleCategoryEn: "Google top 3"
      },
      {
        id: 19,
        TitleCategoryRu: "Фотосъёмка",
        TitleCategoryUz: "Fotosurat",
        TitleCategoryEn: "Photography"
      },
      {
        id: 20,
        TitleCategoryRu: "Видеосъёмка",
        TitleCategoryUz: "Video s'yomka",
        TitleCategoryEn: "video filming"
      },
      {
        id: 21,
        TitleCategoryRu: "Анимационный ролик",
        TitleCategoryUz: "Animatsion rolik",
        TitleCategoryEn: "animated clip"
      }
  ]