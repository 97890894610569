import styled from "styled-components";

export const Background = styled.div`
    margin-bottom: 40px;
    text-align: center;
`
export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`
export const Buttons = styled.div`
display: flex;
justify-content: space-around;
width: 1-0%;
button{
    background-color: #FFD700;
    color: black;
    font-size: 18px;
    padding: 5px;
    border: none;
    width: 40%;
}
`