import Home from '../router/home/index';
import {Routes , Route, useNavigate, useNavigation, useLocation} from "react-router-dom"
import Projects from '../router/projects';
import Services from '../router/Services';
import Blog from '../router/blog';
import About from '../router/about';
import Team from '../router/team';
import NotFound from '../router/notFound';
import Reviews from '../router/reviews';
import Login from '../router/admin-panel/login';
import AdminDashboard from '../router/admin-panel/dashboard';
import AdminClient from '../router/admin-panel/client';
import AdminTeam from '../router/admin-panel/team';
import AdminVaucher from '../router/admin-panel/vaucher';
import Cookies from 'universal-cookie/es6';
import AdminConnect from '../router/admin-panel/connect/index';
import AdminAdmins from '../router/admin-panel/admin';
import AdminProject from '../router/admin-panel/projects';
import AdminBlog from '../router/admin-panel/blog';
import AdminReviews from '../router/admin-panel/reviews';
import BlogId from '../router/blogId';
import { useEffect, useRef } from 'react';
function RoutesPage() {
    const cookies = new Cookies();
    const audioRef = useRef();
    const pathname = useLocation();
    function Token() {
      return cookies.get("access")
    }

     
    return(
        <>
<Routes>
  <Route path='/' element={<Home/>} />
  <Route path='/projects' element={<Projects/>} />
  <Route path='/services' element={<Services/>} />
  <Route path='/blog' element={<Blog/>} />
  <Route path='/about' element={<About/>} />
  <Route path='/team' element={<Team/>} />
  <Route path='/reviews' element={<Reviews/>} />
  <Route path="*"  element={<NotFound/>} status={404} />
  <Route path='/admin' element={<Login/>} />
  <Route path='/:titleUz/:id' element={<BlogId/>}/>
    {Token()?<Route path='/admin/dashboard' element={<AdminDashboard/>} /> :null}
    {Token()?<Route path='/admin/client' element={<AdminClient/>} /> :null}
    {Token()?<Route path='/admin/team' element={<AdminTeam/>} /> :null}
    {Token()?<Route path='/admin/vaucher' element={<AdminVaucher/>} /> :null}
    {Token()?<Route path='/admin/connect' element={<AdminConnect/>} /> :null}
    {Token()?<Route path='/admin/admins' element={<AdminAdmins/>} /> :null}
    {Token()?<Route path='/admin/project' element={<AdminProject/>} /> :null}
    {Token()?<Route path='/admin/blog' element={<AdminBlog/>} /> :null}
    {Token()?<Route path='/admin/reviews' element={<AdminReviews/>} /> :null}
</Routes>
        </>
    )
}
export default RoutesPage