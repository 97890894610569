import styled from "styled-components";

export const Buttons = styled.button`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 23px;
text-transform: uppercase;
color: #1B1B1B;
background: #FFD700;
border: 1px solid transparent;
cursor: pointer;
:hover{
    border: 1px solid white;
    background: #FFE767;
}
`