import './Dashboard_Header.css'
import Logo from './../../../assets/login/logo.svg'
import { NavLink } from 'react-router-dom'


function Header({hover1}) {
        return(
            <>
        <div class="navigation">
            <ul>
                <li >
                    <a href="/admin/dashboard">
                        <span class="icon">
                            <img class="iconLogo" src={Logo} width="55" height="55" alt="" />
                        </span>
                        <span class="title">SUPERSITE.UZ</span>
                    </a>
                </li>
                <li>
                    <NavLink to={'/admin/dashboard'}>
                        <span class="icon">
                        <ion-icon name="aperture-outline"></ion-icon>
                        </span>
                        <span class="title">Статистика</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/admin/project'}>
                        <span class="icon">
                            <ion-icon name="settings-outline"></ion-icon>
                        </span>
                        <span class="title">Наши работы</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={'/admin/client'}>
                        <span class="icon">
                            <ion-icon name="people-outline"></ion-icon>
                        </span>
                        <span class="title">Наши клиенты</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/admin/vaucher'}>
                        <span class="icon">
                            <ion-icon name="paper-plane-outline"></ion-icon>
                        </span>
                        <span class="title">Заявка по ваучеру</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/admin/connect'}>
                        <span class="icon">
                            <ion-icon name="people-circle-outline"></ion-icon>
                        </span>
                        <span class="title">Cвязаться с нами</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/admin/team'}>
                        <span class="icon">
                            <ion-icon name="man-outline"></ion-icon>
                        </span>
                        <span class="title">Наша команда</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/admin/admins'}>
                        <span class="icon">
                            <ion-icon name="person-circle-outline"></ion-icon>
                        </span>
                        <span class="title">Админ</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/admin/blog'}>
                        <span class="icon">
                            <ion-icon name="options-outline"></ion-icon>
                        </span>
                        <span class="title">Блог</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/admin/reviews'}>
                        <span class="icon">
                        <ion-icon name="caret-forward-circle-outline"></ion-icon>
                        </span>
                        <span class="title">ОТЗЫВЫ</span>
                    </NavLink>
                </li>
            </ul>
        </div>
            </>
        )
}
export default Header