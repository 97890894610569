import styled from "styled-components";
import HomeHero from "./../../../assets/home/homeHero.png"
import HomeHeroTable from "./../../../assets/home/home-hero-media.png"
import HomeHeroMobile from "./../../../assets/home/home-hero-mobile.png"

export const Section = styled.div`
    background-image: linear-gradient(rgba(27, 27, 27, 0.8),rgba(27, 27, 27, 0.8)), url(${HomeHero});
    width: 100%;
    height: 771px;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width:1200px) {
        background-image: linear-gradient(rgba(27, 27, 27, 0.8),rgba(27, 27, 27, 0.8)), url(${HomeHeroTable});
    width: 100%;
    height: 771px;
    background-repeat: no-repeat;
    background-size: cover;
}
@media only screen and (max-width:604px) {
        background-image: linear-gradient(rgba(27, 27, 27, 0.8),rgba(27, 27, 27, 0.8)), url(${HomeHeroMobile});
    width: 100%;
    height: 563px;
    background-repeat: no-repeat;
    background-size: cover;
}
` 
export const Wrapper = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 259px;
    p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 37px;
    }
    p span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 22px;
        color: #FFD700;
        margin-left: 6px;
        margin-right: 5px;
    }
    h1{
        width: 100%;
        max-width: 1045px;
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 80px;
        line-height: 86px;
        text-transform: uppercase;
        color: #FFD700;
    }
    h1 span{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 80px;
        line-height: 86px;
        text-transform: uppercase;
        color: white;
        margin-left: 10px;
    }
    @media only screen and (max-width:1200px) {
        width: 95%;
}
@media only screen and (max-width:760px) {
    padding-top: 103px;
    h1{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 46px;
        line-height: 56px;
        text-transform: uppercase;
        color: #FFD700;
    }
    h1 span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 43px;
        line-height: 56px;
        text-transform: uppercase;
        color: white;
    }
}
@media only screen and (max-width:600px) {
    padding-top: 63px;
    h1{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 46px;
        line-height: 56px;
        text-transform: uppercase;
        color: #FFD700;
    }
    h1 span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 43px;
        line-height: 56px;
        text-transform: uppercase;
        color: white;
    }
}
`


